import { ref, readonly, computed, toValue, watch } from 'vue';

function linear(x) {
    return x;
}

export function useAnimatedNumber({ number, defaultValue = false, formatter = null, skipInitial = false, duration = 1000, easingFunction = linear }) {
    const displayNumber = ref(null);
    const isAnimating = ref(false);
    let prevNumber = toValue(number);

    const formattedDisplayNumber = computed(() => {
        if (displayNumber.value === null) {
            return toValue(defaultValue);
        }

        if (formatter === null) {
            return displayNumber.value;
        }

        return formatter(displayNumber.value);
    });

    let startTime;
    function stepAnimation(currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }

        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        displayNumber.value = Math.floor(prevNumber + (toValue(number) - prevNumber) * easingFunction(progress));

        if (progress < 1) {
            requestAnimationFrame(stepAnimation);
        } else {
            isAnimating.value = false;
        }
    }

    watch(
        () => toValue(number),
        () => {
            if (toValue(number) === null) {
                displayNumber.value = null;
                return;
            }

            if (toValue(number) === displayNumber.value) {
                return;
            }

            if (skipInitial && displayNumber.value === null) {
                displayNumber.value = toValue(number);
                return;
            }

            if (!Number.isFinite(displayNumber.value)) {
                displayNumber.value = 0;
            }

            // Round display number so the fractional part is reset during animation.
            displayNumber.value = Math.round(displayNumber.value);

            isAnimating.value = true;
            startTime = null;
            prevNumber = displayNumber.value;
            requestAnimationFrame(stepAnimation);
        },
        { immediate: true },
    );

    return {
        displayNumber: readonly(displayNumber),
        isAnimating: readonly(isAnimating),
        formattedDisplayNumber,
    };
}
