import defaultsDeep from 'lodash/defaultsDeep';
import { getLocale, getNumberFormatter, getPercentFormatter } from 'JIX/utils.js';

import { __ } from 'JIX/gettext.js';

const daLangOptions = {
    "da-DK": {
        decimalPoint: ",",
        thousandsSep: ".",
        shortMonths: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
        months: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
    },
    "en-GB": {
        decimalPoint: ".",
        thousandsSep: ",",
    },
};

export function getGlobalChartOptions({ options = {}, chart_id = null } = {}) {
    // We provide the id of just a single chart on the given page in order
    // to get the correct language for all charts on the page. This is
    // necessary for pages where the content should still be in danish even though
    // english was chosen by the user - for instance day2day.
    const chart_element = document.getElementById(chart_id);
    const default_options = {
        lang: {
            noData: __('Der er ingen data at vise'),
            ...daLangOptions[getLocale({ element: chart_element })],
        },
    };

    defaultsDeep(options, default_options);

    return options;
}

export function getGenericChartConfig(options = {}) {
    const default_config = {
        title: {
            text: "Generic Chart",
        },
        chart: {
            allowMutatingData: false,
            borderWidth: 1,
            style: {
                fontFamily: 'inherit',
            },
        },
        xAxis: {
            dateTimeLabelFormats: {
                day: {
                    main: "%e. %b", // e.g. '3. apr'
                },
                week: {
                    main: "%e. %b",
                },
            },
        },
        yAxis: {
            title: {
                text: undefined,
            },
        },
        plotOptions: {
            series: {
                custom: {
                    // Workaround function for getting the correct decimalpoint and
                    // thousandseparator for the given locale.
                    // There is a bug in Highcharts where the global settings
                    // are ignored in custom tooltips.
                    // It does not seem like it is actively being worked on.
                    // https://github.com/highcharts/highcharts/issues/8101
                    getLocaleNumbers: function(num) {
                        return getNumberFormatter().format(num);
                    },
                    getLocalePercent: function(num) {
                        return getPercentFormatter().format(num / 100);
                    },
                },
            },
        },
        series: [
            {
                data: [],
            },
        ],
        credits: {
            enabled: false,
        },
    };

    // Mutates 'options' with any 'default_config' options
    // that are missing from 'options'
    defaultsDeep(options, default_config);

    return options;
}
