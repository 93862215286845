<script setup>
import { toRef, computed, watch } from 'vue';

import { useAnimatedNumber } from 'JIX/animatedNumber.js';

const props = defineProps({
    /**
     * The number to display.
     */
    number: {
        type: Number,
        default: null,
    },
    /**
     * String to display when `number` is null.
     */
    default: {
        type: String,
        default: "",
    },
    /**
     * Format the number using this function.
     */
    formatter: {
        type: Function,
        default: null,
    },
    /**
     * Do not animate the initial value of the number (from 0).
     */
    skipInitial: {
        type: Boolean,
        default: false
    },
    /**
     * Add this class to the element while animating.
     */
    animatingClass: {
        type: String,
        default: undefined,
    },
});

const emit = defineEmits(['animation-begin', 'animation-end']);

const number = toRef(props, 'number');

const { formattedDisplayNumber, isAnimating } = useAnimatedNumber({
    number,
    defaultValue: props.default,
    formatter: props.formatter,
    skipInitial: props.skipInitial,
});

const spanClass = computed(() => {
    if (props.animatingClass && isAnimating.value) {
        return [props.animatingClass];
    }
    return [];
});

watch(
    () => isAnimating.value,
    () => {
        if (isAnimating.value) {
            emit('animation-begin');
        } else {
            emit('animation-end');
        }
    }
);
</script>

<template>
    <span :class="spanClass">{{ formattedDisplayNumber }}</span>
</template>
