<script>
export const TYPES = Object.freeze(['success', 'warning', 'danger', 'info']);
</script>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import jQuery from 'jquery';

const props = defineProps({
    type: {
        type: String,
        required: true,
        validator: (v) => TYPES.includes(v),
    },
    dismissable: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(['close', 'closed']);

const root = ref();
onBeforeUnmount(() => jQuery(root.value).alert('dispose'));

const classes = computed(() => {
    return ['alert', 'alert-' + props.type, { 'alert-dismissible': props.dismissable } ];
});

const dismissed = ref(false);
function close(event) {
    emit('close', event);
    dismissed.value = true;
    emit('closed', event);
}

defineExpose({
    close
});

</script>

<template>
    <div
        v-if="!dismissed"
        :class="classes"
        role="alert"
        ref="root"
    >
        <button
            type="button"
            class="close"
            @click="close"
            aria-label="Close"
            v-if="dismissable"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <slot />
    </div>
</template>
