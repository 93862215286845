<script setup>
import { ref, watch } from 'vue';
import $ from 'jquery';
import debounce from 'lodash/debounce';

import AnimatedNumber from 'JIX/components/AnimatedNumber.vue';
import DsLinkBlock from 'JIX/components/ds/DsLinkBlock.vue';
import DsIconInfoBox from 'JIX/components/ds/DsIconInfoBox.vue';
import Select2 from 'JIX/components/Select2.vue';
import jobTitlesConfig from 'JIX/select2/cv_jobtitles.js';
import getGeoAreaConfig from 'JIX/select2/geoarea.js';
import ShowMore from 'JIX/components/ShowMore.vue';
import DsAlert from 'JIX/components/ds/DsAlert.vue';

import { ThemeColors, getThemeColor } from 'JIX/utils/color.js';
import { getStandardColumnChartConfig } from 'JIX/highcharts/configs/column_chart.js';

import 'icons/add-round.svg';
import 'icons/service-jobsearch.svg';

import T from './SalaryIndex.i18n.js';

const props = defineProps({
    salaryindexRoot: {
        type: String,
        required: true,
    },
    jobsearchRoot: {
        type: String,
        required: true,
    },

    salaryApiKey: {
        type: String,
        required: true,
    },
    salaryApiLink: {
        type: String,
        required: true,
    },
    salaryOptions: {
        type: Object,
        required: true,
    },
    educationOptions: {
        type: Array,
        required: true,
    },
    geoareas: {
        type: Array,
        required: true,
    },
    initialJobtitles: {
        type: Array,
        default() {
            return [];
        }
    },
    initialGeoareaid: {
        type: Array,
        default() {
            return [];
        }
    },
    initialEducationlevel: {
        type: Number,
        default: -1,
    },
    initialJobexperience: {
        type: Number,
        default: null,
    },
    initialMgrexperience: {
        type: Number,
        default: null,
    },
    initialAge: {
        type: Number,
        default: null,
    },
    urls: {
        type: Object,
        required: true,
    },
});

const brand = { color: getThemeColor(ThemeColors.BRAND).toString() };
const black40 = { color: '#999999' };

// Store original search/query params for when we update the URL
const searchParams = new URL(location.href).searchParams;

// Result
const expectedSalary = ref(null);
const expectedSalaryDefault = "__.___ kr.";

// Form
const selectedJobtitles = ref(props.initialJobtitles.map(j => j.id));
const selectedGeoareas = ref([...props.initialGeoareaid]);
const educationlevel = ref(props.initialEducationlevel);
const jobexperience = ref(props.initialJobexperience);
const mgrexperience = ref(props.initialMgrexperience);
const age = ref(props.initialAge);

// UI
const jobtitleConfig = jobTitlesConfig({
    minimumInputLength: 2,
    maximumSelectionLength: 10,
    mode: 'salaryindex',
});
const geoareaConfig = getGeoAreaConfig({
    context: 'cv',
    data: props.geoareas,
    default_list: props.geoareas,
    radius: false,
});

const errorMessage = ref(null);
const errorFields = ref([]);

const salaryChartOptions = ref(getStandardColumnChartConfig({
    chart: {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        borderWidth: 0,
        height: 300,
    },
    title: {
        text: null,
    },
    xAxis: {
        categories: ["10–20k", "20–30k", "30–40k", "40–50k", "50–60k"],
        labels: {
            style: black40,
        },
    },
    yAxis: {
        title: {
            text: T.numberOfCVsText(),
        },
        allowDecimals: false,
    },
    series: [
        {
            name: T.numberOfCVsText(),
            data: [0, 0, 0, 0, 0],
            color: brand.color,
        }
    ],
}));

const searchMaybeDebounced = debounce(searchMaybe, 500, { leading: true, trailing: true });

const jobsearchUrl = ref(props.jobsearchRoot);

watch(
    [
        selectedJobtitles,
        selectedGeoareas,
        educationlevel,
        jobexperience,
        mgrexperience,
        age,
    ],
    searchMaybeDebounced,
    { deep: true }
);

searchMaybe();

function sendAnalyticsEvent(label) {
    window.jixAnalytics('event', {
        category: 'salaryindex',
        action: 'click',
        label,
    });
}

function clearResult() {
    expectedSalary.value = null;
    updateSalaryChart({
        labels: ["10-20k", "20-30k", "30-40k", "40-50k", "50-60k"],
        data: [0, 0, 0, 0, 0]
    });
    updateUrl([]);
    jobsearchUrl.value = props.jobsearchRoot;
}

function reanimate() {
    if (expectedSalary.value !== null) {
        let tmp = expectedSalary.value;
        expectedSalary.value -= 5000;
        window.setTimeout(() => {
            if (expectedSalary.value !== null) {
                expectedSalary.value = tmp;
            }
        }, 200);
    }
}

function searchMaybe() {
    if (selectedJobtitles.value && selectedJobtitles.value.length > 0) {
        search();
    } else {
        clearResult();
    }
}

function search() {
    const query = {
        key: props.salaryApiKey,
        jobtitle: selectedJobtitles.value,
        geoareaid: selectedGeoareas.value,
    };

    if (educationlevel.value !== -1) {
        query.educationlevel = educationlevel.value;
    }

    // Don't use 'field-with-error' class if age is out of range; just omit.
    if (age.value > 15 && age.value < 100) {
        query.age = age.value;
    }

    if (jobexperience.value !== null) {
        query.jobexperience = jobexperience.value;
    }

    if (mgrexperience.value !== null) {
        query.mgrexperience = mgrexperience.value;
    }

    $.ajax(props.salaryApiLink, { data: query, traditional: true })
        .done(response => {
            if (Object.prototype.hasOwnProperty.call(response, 'error')) {
                errorMessage.value = response.description;

                if (response.errorid === "invalid-value") {
                    errorFields.value = response.parameters;
                }

                clearResult();
                return;
            }

            expectedSalary.value = parseInt(response.avgsalary.avg, 10);
            errorMessage.value = null;
            errorFields.value = [];
            updateUrl(response.jobtitles);
            jobsearchUrl.value = response.jobsearch_url;
            updateSalaryChart(response.histogram);
        })
        .fail(_response => {
            errorMessage.value = "Der opstod en fejl.";
            errorFields.value = [];
        });
}

function updateSalaryChart(histogram) {
    salaryChartOptions.value.series[0].data = histogram.data;
    salaryChartOptions.value.xAxis.categories = histogram.labels;
}

function formatCurrency(value, thousandSep = '.') {
    value = Math.floor(value);
    let chunks = [];
    while (value >= 1000) {
        let chunk = (value % 1000).toString().padStart(3, "0");
        chunks.unshift(chunk);
        value = Math.floor(value / 1000);
    }
    chunks.unshift(value.toString());
    return chunks.join(thousandSep) + " " + T.krText();
}

function updateUrl(jobtitles) {
    const url = new URL(props.salaryindexRoot);
    searchParams.forEach((value, key) => url.searchParams.append(key, value));
    if (jobtitles.length === 1) {
        url.pathname += '/' + jobtitles[0];
    }
    window.history.replaceState(null, null, url);
}

function analytics(label) {
    window.jixAnalytics('event', {
        category: 'salaryindex',
        action:   'click',
        label,
    });
}
</script>

<template>
    <section class="container-fluid bg-surface-1 py-4 py-md-5">
        <section id="salaryindex" class="container">
            <div class="row">
                <div class="col-md-5 offset-md-1">
                    <div class="card p-4">
                        <DsAlert type="danger" v-if="errorMessage !== null">
                            <p>{{ errorMessage }}</p>
                        </DsAlert>

                        <div class="form-group">
                            <label class="control-label" for="jobtitles">
                                {{ T.chooseJobtitlesText() }}
                            </label>
                            <Select2
                                id="jobtitles"
                                v-model="selectedJobtitles"
                                class="form-control"
                                :config="jobtitleConfig"
                            >
                                <option
                                    v-for="j in initialJobtitles"
                                    :key="j.id"
                                    :value="j.id"
                                    selected
                                >
                                    {{ j.name }}
                                </option>
                            </Select2>
                        </div>

                        <div class="form-group">
                            <label class="control-label" for="geoarea">
                                {{ T.chooseGeoAreasText() }}
                            </label>

                            <Select2
                                id="geoarea"
                                v-model="selectedGeoareas"
                                class="form-control"
                                :config="geoareaConfig"
                            />
                        </div>

                        <div class="form-group">
                            <label class="control-label" for="educationlevel">
                                {{ T.chooseEducationLevelText() }}
                            </label>
                            <select v-model="educationlevel" class="form-control">
                                <option
                                    v-for="educationOption in educationOptions"
                                    :key="educationOption[1]"
                                    :value="educationOption[1]"
                                >
                                    {{ educationOption[0] }}
                                </option>
                            </select>
                        </div>

                        <div class="row">
                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label for="jobexperience" class="control-label">
                                        {{ T.chooseJobexperienceText() }}
                                    </label>
                                    <input
                                        v-model="jobexperience"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                    >
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="control-label" for="mgrexperience">
                                        {{ T.chooseMgrexperienceText() }}
                                    </label>
                                    <input
                                        v-model="mgrexperience"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                    >
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="control-label" for="age">
                                        {{ T.chooseAgeText() }}
                                    </label>
                                    <input
                                        v-model="age"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-right pt-2">
                                <button class="btn btn-primary" @click="reanimate(); sendAnalyticsEvent('update')">
                                    {{ T.updateText() }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-5">
                    <div class="row bg-surface-1 box-shadow d-flex fixed-bottom position-md-static p-2 p-md-0">
                        <div class="align-self-center col-6 col-md-5 offset-md-1">
                            <h5>{{ T.averageSalaryText() }}</h5>
                        </div>

                        <div class="col-6 text-right">
                            <h1>
                                <AnimatedNumber
                                    class="jix-animated-number text-brand"
                                    :number="expectedSalary"
                                    :default="expectedSalaryDefault"
                                    :formatter="formatCurrency"
                                />
                            </h1>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <Highcharts :options="salaryChartOptions" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-11 offset-md-1">
                            <ShowMore
                                :max-height="150"
                            >
                                <p>{{ T.explanationText1() }}</p>
                                <p>{{ T.explanationText2() }}</p>
                            </ShowMore>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="container py-4 py-md-5">
        <div class="articles-list row row-gap-4 justify-content-center d-flex">
            <div class="col-lg-5 d-flex">
                <DsIconInfoBox
                    :heading="T.jobsearchCardHeader()"
                    :url="jobsearchUrl"
                    :text="T.jobsearchCardText()"
                    @link-click="analytics(T.searchJob())"
                    :link-text="T.searchJob()"
                    icon="service-jobsearch"
                >
                    {{ T.jobsearchCardText() }}
                </DsIconInfoBox>
            </div>

            <div class="col-lg-5 d-flex">
                <DsIconInfoBox
                    :heading="T.createCvHeader()"
                    :url="urls.cv"
                    @link-click="analytics(T.createCv())"
                    :link-text="T.createCv()"
                    icon="add-round"
                >
                    {{ T.createCvText() }}
                </DsIconInfoBox>
            </div>
        </div>
    </section>

    <section class="container-fluid bg-surface-1 py-4 py-md-5">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <h2>{{ T.otherJobtitlesHeader() }}</h2>
                <div class="row">
                    <div class="col-xl-4 col-md-6" v-for="item in urls.jobtitles" :key="item.jobtitle">
                        <DsLinkBlock :href="item.url">
                            {{ item.jobtitle }}
                        </DsLinkBlock>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
